import { graphql, Link } from 'gatsby';
import propTypes from 'prop-types';
import React, { useMemo } from 'react';

import PageTitle from '../components/PageTitle';
import CategoryPreview from '../components/CategoryPreview';
import ProductCard from '../components/ProductCard';
import ProductGrid from '../components/ProductGrid';
import Layout from '../layouts/Layout';
import { productFactory } from '../services/productFactory';

const Shop = ({ data }) => {
  const products = useMemo(() => {
    return data.allMarkdownRemark.nodes.map(productFactory);
  }, [data]);
  const categories = data.allCategoriesJson.nodes;

  const categoryPreviews = categories.reduce((acc, category) => {
    const categoryProducts = products.filter(product => product.category && product.category.id === category.id);
    acc.push({
      category,
      products: categoryProducts.slice(0,5),
    });
    return acc;
  }, []);

  return (
    <Layout title="CVNNABIS.com - All things cannabis">
      <div className="w-full">
        <PageTitle title="Join 20,000+ stoners discovering the world of Cannabis." />
        {categoryPreviews.map(({category, products}, i) => (<CategoryPreview category={category} products={products} key={i}/>))}
{/*         <h2 className="text-black text-xl sm:text-3xl font-extrabold lg:text-4xl leading-none mb-4">All products</h2>
        <ProductGrid>
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </ProductGrid> */}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(products)/" } }) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          name
          price
          image {
            childImageSharp {
              fluid(maxWidth: 272, maxHeight: 363, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          seller {
            id
            name
          }
          category {
            id
            name
            metaDescription
            metaKeywords
            fields {
              slug
            }
          }
        }
      }
    }
    allCategoriesJson {
      nodes {
        id
        name
      }
    }
  }
`;

Shop.propTypes = {
  data: propTypes.shape({
    allMarkdownRemark: propTypes.shape({
      nodes: propTypes.array,
    }).isRequired,
  }).isRequired,
};

export default Shop;
