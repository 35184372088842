import React from 'react';
import FadeIn from 'react-fade-in';
import styles from './forms.module.css';


// import Button from '../components/Button';
import TextInput from '../components/TextInput';
// import { useForm } from '../context/FormContext';

const EmailForm = () => {
//   const { handleChange, handleBlur, values, errors, touched, submitForm } = useForm();
  return (
    <FadeIn className="w-full">
      <TextInput
        id="email"
        placeholder="Your Email Address..."
        label="* Get one trend report per month - for free!"
        // value={values.email}
        // onChange={handleChange('email')}
        // onBlur={handleBlur('email')}
        // error={touched['email'] ? errors['email'] : null}
      />
      {/* <div className="w-full sm:w-1/3 sm:pr-4">
        <div className="w-full max-w-lg my-2">
            <Button text="Get your reports" onClick={} />
        </div>
      </div> */}
    </FadeIn>
  );
};

export default EmailForm;
