import { Link } from 'gatsby';
import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import styles from './categorypreview.module.css';
import ProductCard from '../ProductCard';

const CategoryPreview = ({ products, category }) => {
  const responsive = {
    0: { items: 1 },
    280: { items: 2 },
    540: { items: 3 },
    1250: { items: 5 },
  };
  const items = products.map((product, i) => (
    <div key={i}>
      <ProductCard product={product} />
    </div>
  ));
  items.push(
    <div className="px-1">
      <div className="relative pb-4/3 -mr-2">
        <div className={`absolute w-full h-full object-cover flex rounded-md justify-items-center items-center border border-gray-200 bg-gray-100 hover:bg-gray-200`}>
          <Link
            to={`/categories/${category.id}`}
            draggable={false}
            className="text-black text-md text-center w-full font-extrabold leading-none px-4">
            <div className="mb-4">
              <button className={`p-0 w-16 h-16 bg-black rounded-full focus:outline-none active:translate-y-1 transform ${styles.categoryShadow}`}>
                <svg viewBox="0 0 20 20" enableBackground="new 0 0 20 20" className="w-6 h-6 inline-block">
                  <path fill="#FFFFFF" d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601
                                          C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399
                                          C15.952,9,16,9.447,16,10z">
                  </path>
                </svg>
              </button>
            </div>
            Show more {category.name}
          </Link>
        </div>
      </div>
    </div>
  );

  while (items.length < 5) {
    items.push(undefined);
  }

  return products.length ? (
    <div className="pt-8 border-solid border-b border-gray-300">
        <Link
          to={`/categories/${category.id}`}
          className="flex w-full text-black text-2xl sm:text-2xl font-extrabold lg:text-2xl leading-normal"
        >
          {category.name}
        </Link>
        <p className="mb-1">{category.metaDescription} </p>
      <div className="overflow-hidden -mr-4 -ml-6">
        <div className="mt-4">
          <AliceCarousel
            items={items}
            paddingRight={20}
            paddingLeft={20}
            mouseTracking
            responsive={responsive}
            disableDotsControls
            disableButtonsControls
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CategoryPreview;
