const productFactory = product => ({
  id: product.id,
  image: product.frontmatter.image,
  price: product.frontmatter.price,
  description: product.frontmatter.description,
  name: product.frontmatter.name,
  seller: product.frontmatter.seller,
  tags: product.frontmatter.tags,
  category: product.frontmatter.category,
  metaDescription: product.frontmatter.metaDescription,
  metaKeywords: product.frontmatter.metaKeywords,
  url: product.frontmatter.url,
  bank: product.frontmatter.bank,
  slug: product.fields.slug,
  html: product.html
});

export { productFactory };
