import classNames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import Button from '../../components/Button';
import styles from './textinput.module.css';
import { noop } from '../../util/utils';

const TextInput = ({
  id,
  placeholder,
  label,
  value,
  onChange,
  onBlur,
  error,
  maxLength,
  formatText,
}) => {
  const inputClass = classNames(
    'h-12 appearance-none rounded-sm w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white',
    {
      'bg-gray-200': !error,
      'bg-white': !!error,
      'border-black': !error,
      'border-red-400': !!error,
      'focus:border-black': !error,
      'focus:border-red-400': !!error,
    }
  );

  const onChangeText = ({ target }) => {
    let { value } = target;
    if (typeof formatText === 'function') {
      value = formatText(value);
    }

    onChange(value);
  };

  return (
  <div className="w-full md:w-4/6 flex flex-wrap">
    <div className="w-full sm:w-2/3 sm:pr-4">
      <div className="w-full max-w-lg my-2">
        <input
          className={`${inputClass} ${styles.pageTitleShadow}`}
          id={id}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChangeText}
          onBlur={onBlur}
          maxLength={maxLength}
        />
        <label className="block text-white text-xs italic mt-1" htmlFor={id}>
          {label}
        </label>
        {!!error && <span className="text-red-500 font-regular text-xs">{error}</span>}
      </div>
    </div>
    <div className="w-full sm:w-1/3 sm:pr-4">
      <div className="w-full max-w-lg my-2">
          <Button className={`${styles.pageTitleShadow} border-none`} text="Get your reports" />
      </div>
    </div>
  </div>
  );
};

TextInput.propTypes = {
  id: propTypes.string.isRequired,
  placeholder: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  value: propTypes.string,
  onChange: propTypes.func.isRequired,
  onBlur: propTypes.func.isRequired,
  error: propTypes.string,
  maxLength: propTypes.number,
  formatText: propTypes.func,
};

TextInput.defaultProps = {
  error: null,
  maxLength: 200,
  formatText: null,
  onBlur: noop,
  onChange: noop,
};

export default TextInput;
