import propTypes from 'prop-types';
import React from 'react';
import styles from './pagetitle.module.css';
import EmailForm from '../../forms/EmailForm.js';

const PageTitle = ({ title }) => {
  return (
    <div className={`p-6 md:p-12 mb-8 rounded-md ${styles.hero}`}>
      <h1 className="text-black md:w-5/6 text-3xl md:text-4xl sm:text-5xl md:text-5xl font-bold">
        <span className="text-white">{title}</span>
      </h1>
      <h3 className="text-white text-lg font-bold mb-12">50+ hours of trend market research in 5-minute reports.</h3>
      <EmailForm />
    </div>
  );
};

PageTitle.propTypes = {
  title: propTypes.string.isRequired,
};

export default PageTitle;
